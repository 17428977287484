import {
  useAnchorWallet,
  useConnection,
  useWallet,
} from "@solana/wallet-adapter-react"
import React, { useEffect, useState } from "react"
import { ArticleItem } from "../../components/ArticleItem"
import { Page } from "../../components/Page"
import { createSignature } from "../../helpers/signMessage"

type Writer = {
  twitterHandle: string
  wallet?: string
  picture?: string
  label: string
  documents: {
    slug: string
    wallet: string
    createTime: number
    updateTime: number
    amountUpvotes: number
    title: string
  }[]
}

const labels = ["", "Influencer", "DAO"]

const getWriter = async (id: string) => {
  try {
    const resultRes = await fetch(
      `${process.env.GATSBY_BACKEND_ENDPOINT}getWriter/${id}`
    )
    if (resultRes.status === 200) {
      return resultRes.json()
    }
  } catch (e) {
    // pass
  }
}

export default function Home({ params }: { params: { id: string } }) {
  const [writer, setWriter] = useState<Writer>()
  const [message, setMessage] = useState("")
  const { publicKey, signMessage } = useWallet()
  const { connection } = useConnection()
  const wallet = useAnchorWallet()

  useEffect(() => {
    getWriter(params.id).then(setWriter)
  }, [params.id])

  const updateLabel = async (e: any) => {
    if (!writer) {
      return
    }

    const label = e.target.value || ""
    const useLedger =
      localStorage.getItem("useLedger") === "true" ? true : false
    let signature
    try {
      signature = await createSignature(
        connection,
        wallet,
        publicKey,
        signMessage,
        `Add label ${label} to writer ${writer.wallet}`
      )
    } catch (e: any) {
      // Do nothing
      return
    }

    const res = await fetch(
      `${process.env.GATSBY_BACKEND_ENDPOINT}updateWriter`,
      {
        method: "POST",
        body: JSON.stringify({
          writer: writer.wallet,
          signature,
          label,
          publicKey: (publicKey || "").toString(),
          ledger: useLedger,
        }),
      }
    )
    const result = await res.json()
    if (result.status === "success") {
      setMessage("Label Saved")
    }
  }

  if (!writer) {
    return (
      <Page>
        <React.Fragment></React.Fragment>
      </Page>
    )
  }

  return (
    <Page>
      {process.env
        .GATSBY_ADMIN_LIST!.split(",")
        .includes((publicKey || "").toString()) &&
        writer && (
          <div>
            Label:
            <select onChange={updateLabel}>
              {labels.map(label => (
                <option
                  key={label}
                  value={label}
                  selected={label === writer?.label}
                >
                  {label}
                </option>
              ))}
            </select>
            {message}
          </div>
        )}
      <h3>{writer.twitterHandle || writer.wallet}</h3>
      {writer.label && <h4>{writer.label}</h4>}
      <h5>Articles written</h5>
      <div className="threeToOneColumnCount">
        {writer.documents.map(document => (
          <div key={document.slug}>
            <ArticleItem
              to={`/d/${document.slug}`}
              writer={writer.twitterHandle || document.wallet}
              label={writer.label}
              title={document.title}
              amountUpvotes={document.amountUpvotes}
              picture={writer?.picture}
            />
          </div>
        ))}
      </div>
    </Page>
  )
}
